import { h } from 'preact'
import { Suspense, lazy } from 'preact/compat'

import { StateProvider } from '../state-management/store'
import { SocketProvider } from './socket-provider'
import locationHash from '../shared/locationHash'
import { CenterSpinner } from './spinner'

const WidgetRouter = lazy(() => import('./widget-router/widget-router'))

if ((module as any).hot) {
    // tslint:disable-next-line:no-var-requires
    require('preact/debug')
}

declare global {
    interface Window {
        dataLayer?: any[]
        gtag: any
    }
}

const App: preact.FunctionalComponent = () => {
    return (
        <StateProvider>
            <SocketProvider>
                <div id="app">
                    <Suspense fallback={<CenterSpinner />}>
                        <WidgetRouter />
                    </Suspense>
                </div>
            </SocketProvider>
        </StateProvider>
    )
}

function getURLHost(startingUrl: string | undefined) {
    let url

    try {
        url = new URL(startingUrl || '')
    } catch (_) {
        return undefined
    }

    return url.host
}

function isBlackListedDomainForGtm() {
    const blackListedDomains = (process.env.PREACT_APP_GTM_BLACK_LISTED_DOMAINS as string).split(',')
    const documentReferrer = document?.referrer

    const startingUrlDecoded =
        (locationHash.startingUrl &&
            locationHash.startingUrl !== 'undefined' &&
            decodeURIComponent(locationHash.startingUrl)) ||
        documentReferrer ||
        ''

    const currentDomainHost = getURLHost(startingUrlDecoded)

    const currentDomain = currentDomainHost?.replace(/^www\./i, '')

    return currentDomain ? blackListedDomains.includes(currentDomain) : false
}

if (!isBlackListedDomainForGtm()) {
    ;(function (w: any, d: any, s: string, l: string, i?: string) {
        w[l] = w[l] || []
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
        const f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : ''
        j.async = true
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
        f.parentNode.insertBefore(j, f)
    })(window, document, 'script', 'dataLayer', process.env.PREACT_APP_GTM_PROPERTY_ID)
}

function loadScriptAsync(scriptSrc: string, callback: () => void) {
    if (typeof callback !== 'function') {
        throw new Error('Not a valid callback for async script load')
    }
    const script = document.createElement('script')
    script.onload = callback
    script.src = scriptSrc
    document.head.appendChild(script)
}

function loadClientTagManagerContainer() {
    if (typeof locationHash.gtmID !== 'string' || locationHash.gtmID?.length <= 5) {
        return
    }
    loadScriptAsync(`https://www.googletagmanager.com/gtm.js?id=${locationHash.gtmID}`, function () {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
    })
}

loadClientTagManagerContainer()

if (!isBlackListedDomainForGtm()) {
    loadScriptAsync(
        `https://www.googletagmanager.com/gtag/js?id=${process.env.PREACT_APP_GA_PROPERTY_ID}`,
        function () {
            window.dataLayer = window.dataLayer || []
            window.gtag = function gtag() {
                window.dataLayer?.push(arguments) // eslint-disable-line prefer-rest-params
            }
            window.dataLayer.push({ simplifeyeGA4ID: locationHash.gaID })
        }
    )
}

export default App

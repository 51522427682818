import { h, createContext } from 'preact'
import { useReducer, useContext } from 'preact/hooks'
import { State, Actions, reducer, initialState } from './reducer'
import * as persister from './persist'
import { throttle } from '../shared/utils'

type ContextProps = [State, (action: Actions) => void]

const StateContext = createContext<ContextProps>({} as ContextProps)
const throttledPersist: (args: State) => void = throttle(persister.persist, 1000)

export function StateProvider({
    children,
    customInitialState,
}: {
    children: any
    customInitialState?: Partial<State>
}) {
    const [state, dispatch] = useReducer(reducer, { ...initialState, ...customInitialState })

    throttledPersist(state)

    return <StateContext.Provider value={[state, dispatch]}>{children}</StateContext.Provider>
}

export function useStateValue() {
    const store = useContext(StateContext)
    if (!store) {
        throw new Error('Cannot use `useStateValue` outside of a StateProvider')
    }
    return store
}

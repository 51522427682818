export enum BookingStep {
    LOCATION = 0,
    PATIENT_TYPE = 1,
    DETAILS = 2,
    ADDITIONAL_INFO = 3,
    DATETIME = 4,
    CONFIRM = 5,
    RESERVING = 6,
    TO_BE_BOOKED = 7,
    BOOKED = 8,
    BOOKING_FAILED = 9,
}

export enum AppointmentManagementStep {
    AUTHENTICATION = 0,
    ACTION_TYPE_SELECTION = 1,
    RESCHEDULE_DATETIME = 2,
    RESCHEDULE_CONFIRM = 3,
    RESCHEDULE_RESERVING = 4,
    RESCHEDULED = 5,
    CANCEL = 6,
    CANCEL_CONFIRM = 7,
    CANCELED = 8,
    CONFIRM = 9,
    CONFIRM_RESERVING = 10,
    CONFIRMED = 11,
}

export enum AppointmentManagementTypes {
    COMPLETE = 'complete',
    NO_CONFIRMATION = 'no-confirmation',
}

export enum AppointmentManagementUserActions {
    CANCEL = 'CANCEL',
    CONFIRM = 'CONFIRM',
    RESCHEDULE = 'RESCHEDULE',
}

export enum AppointmentManagementReschedulingStatus {
    RESCHEDULING = 'RESCHEDULING',
    RESCHEDULED = 'RESCHEDULED',
    RESCHEDULING_FAILED = 'RESCHEDULING_FAILED',
}

export enum PatientType {
    NEW = 'new',
    EXISTING = 'existing',
    BOTH = 'both',
}

export enum Sex {
    MALE = 'M',
    FEMALE = 'F',
}

export enum ReschedulingConfigurationType {
    DISABLE = 'disable',
    ALLOW = 'allow',
    REQUEST = 'request',
}

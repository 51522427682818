import { h, createContext, VNode } from 'preact'
import { useRef, useLayoutEffect, useContext } from 'preact/hooks'
import SSDataEventsService from './data-events'
import { useStateValue } from '../../state-management/store'

type ContextProps = {
    socket: any
}

const SocketContext = createContext<ContextProps>({ socket: null })

export function SocketProvider({ children }: { children: VNode | VNode[] }) {
    const socketRef = useRef<any>(null)
    const [, dispatch] = useStateValue()

    useLayoutEffect(() => {
        if (socketRef.current === null) {
            socketRef.current = new SSDataEventsService(dispatch)
            socketRef.current.connect()
        }
    }, [dispatch])

    return <SocketContext.Provider value={{ socket: socketRef.current }}>{children}</SocketContext.Provider>
}

export function useSocketService() {
    const { socket } = useContext(SocketContext)
    return socket
}

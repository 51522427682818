import { h } from 'preact'
import { softGreen } from '../../shared/colors'
import Spinner from './spinner'
import * as style from './style.css'

function CenterSpinner() {
    return (
        <div className={style.centerSpinner}>
            <Spinner color={softGreen} />
        </div>
    )
}

export default CenterSpinner

import * as log from './logger'
import * as persister from './persist'
import { PatientDetails, AdditionalDetails, ValueType, PatientHasAppointmentInLast7DaysModal } from '../models/patient'
import { Procedure, Provider } from '../models/procedure'
import { Location, InsuranceSettings, InsuranceProvider } from '../models/location'
import {
    AppointmentManagementReschedulingStatus,
    AppointmentManagementStep,
    AppointmentManagementUserActions,
    BookingStep,
    PatientType,
    ReschedulingConfigurationType,
} from '../models/enums'
import { Customization, ReschedulingConfiguration } from '../models/customization'
import { ExpiredRSVPData, RSVPData } from '../models/rsvp'
import { validator, ValidatorPreset } from '../shared/validator'
import mergeDeep from '../shared/mergeDeep'
import { uuidv4 } from '../shared/uuid'
import globalVars from '../shared/global'

export interface State {
    errorMessage: string
    statusCode: number | string
    bookingStep: BookingStep
    patientType?: PatientType
    patientDetails: PatientDetails
    additionalDetails: AdditionalDetails
    patientId?: string
    patientHasAppointmentInLast7DaysModal: PatientHasAppointmentInLast7DaysModal
    procedures: Procedure[]
    locations: Location[]
    geocodingStarted: boolean
    selectedLocationId: string
    selectedAppointmentDateRangeFrom: string
    selectedAppointmentDateRangeTo: string
    selectedAppointmentDate: string
    selectedAppointmentTime: string
    treatmentLength?: number
    submitting: boolean
    availableTimes: string[]
    selectedAppointmentAvailableTimes: string[]
    loader: boolean
    customization?: Customization
    hasAdditionalInformation: boolean
    showAdditionalDetailsForm: boolean
    isCustomizationReady: boolean
    expiredRSVPData?: ExpiredRSVPData
    autocompleteSessionUUID: string
    providers: any[]
    insuranceSettings: InsuranceSettings
    insuranceProviders: InsuranceProvider[]
    startingURL?: string
    apptManagementStep: AppointmentManagementStep
    selectedApptUserAction: AppointmentManagementUserActions | null
    managedAppointmentDate: string
    managedAppointmentRescheduleComplete: AppointmentManagementReschedulingStatus
    reschedulingConfiguration: ReschedulingConfiguration
    authKey?: string
}

export type Actions =
    | {
          type: 'HANDLE_ERROR'
          errorMessage: string
          statusCode?: number | string
      }
    | {
          type: 'CHANGE_STEP'
          bookingStep: BookingStep
      }
    | {
          type: 'CHANGE_PATIENT_TYPE'
          patientType: PatientType
      }
    | {
          type: 'UPDATE_PATIENT_DETAILS'
          fieldName: keyof PatientDetails
          value?: ValueType
          error?: string
      }
    | {
          type: 'RESET_PATIENT_DETAILS'
          fieldName: keyof PatientDetails
      }
    | {
          type: 'UPDATE_ADDITIONAL_DETAILS'
          fieldName: keyof AdditionalDetails
          value?: ValueType
          error?: string
          isDirty?: boolean
      }
    | {
          type: 'PROCEDURES_RECEIVED'
          procedures: Procedure[]
      }
    | {
          type: 'RECEIVE_LOCATIONS'
          locations: Location[]
      }
    | {
          type: 'SET_SELECTED_LOCATION'
          locationId: string
      }
    | {
          type: 'HANDLE_SUBMITTING'
      }
    | {
          type: 'PATIENT_SEARCH_NO_RESULTS'
          errorMessage: string
      }
    | {
          type: 'PATIENT_RECEIVED'
          patientPmsId?: string
          patientId?: string
      }
    | {
          type: 'PATIENT_SEARCH_TOO_MANY_RESULTS'
      }
    | {
          type: 'SET_SELECTED_APPOINTMENT_DATE'
          appointmentDate: string
      }
    | {
          type: 'SET_SELECTED_APPOINTMENT_DATE_RANGE'
          from: string
          to: string
      }
    | {
          type: 'SET_SELECTED_APPOINTMENT_TIME'
          appointmentTime: string
      }
    | {
          type: 'RECEIVE_AVAILABLE_TIMES'
          availability: string[]
      }
    | {
          type: 'RESET_APPOINTMENT_DATETIME'
      }
    | {
          type: 'SET_LOADER'
          spin: boolean
      }
    | {
          type: 'RECEIVE_CUSTOMIZATION'
          customization: Customization
      }
    | {
          type: 'CUSTOMIZATION_ERROR'
      }
    | {
          type: 'PATIENT_HAS_APPOINTMENTS_IN_LAST_7_DAYS'
          patientId: string
      }
    | {
          type: 'PATIENT_HAS_APPOINTMENTS_IN_LAST_7_DAYS_CANCEL'
      }
    | {
          type: 'PATIENT_HAS_APPOINTMENTS_IN_LAST_7_DAYS_PROCEED'
          patientId: string
      }
    | {
          type: 'LOAD_RSVP_DATA'
          data: RSVPData
      }
    | {
          type: 'LOAD_EXPIRED_RSVP_DATA'
          data: ExpiredRSVPData
      }
    | {
          type: 'SET_DISTANCE_MATRIX'
          locations: Location[]
      }
    | {
          type: 'PROVIDERS_RECEIVED'
          providers: Provider[]
      }
    | {
          type: 'INSURANCE_SETTINGS_RECEIVED'
          insuranceSettings: InsuranceSettings
      }
    | {
          type: 'INSURANCE_PROVIDERS_RECEIVED'
          insuranceProviders: InsuranceProvider[]
      }
    | {
          type: 'SET_STARTING_URL'
          startingURL: string
      }
    | {
          type: 'CHANGE_APPT_MANAGEMENT_STEP'
          apptManagementStep: AppointmentManagementStep
      }
    | {
          type: 'CHANGE_APPT_MANAGEMENT_USER_ACTION'
          selectedApptUserAction: AppointmentManagementUserActions | null
      }
    | {
          type: 'UPDATE_AUTH_KEY'
          authKey?: string
      }
    | {
          type: 'UPDATE_LOCATION_RESCHEDULING_CONFIGURATION'
          reschedulingConfiguration: Partial<ReschedulingConfiguration>
      }
    | {
          type: 'UPDATE_USER_MANAGED_APPT_DATE'
          managedAppointmentDate: string
      }
    | {
          type: 'UPDATE_USER_MANAGED_APPT_COMPLETION'
          managedAppointmentRescheduleComplete: AppointmentManagementReschedulingStatus
      }

const persistedData = persister.retrieve()
export const initialState: State = {
    errorMessage: '',
    statusCode: '',
    bookingStep: BookingStep.LOCATION,
    patientDetails: {
        firstName: {
            isValid: false,
            isRequired: true,
            validators: [ValidatorPreset.NAME],
        },
        lastName: {
            isValid: false,
            isRequired: true,
            validators: [ValidatorPreset.NAME],
        },
        dateOfBirth: {
            isValid: false,
            isRequired: true,
            validators: [ValidatorPreset.BIRTH_DATE],
        },
        phone: {
            isValid: false,
            isRequired: true,
            validators: [ValidatorPreset.PHONE_NUMBER],
        },
        emailAddress: {
            isValid: false,
            isRequired: true,
            validators: [ValidatorPreset.EMAIL_ADDRESS],
        },
        sex: {
            isValid: true,
            isRequired: false,
            validators: [ValidatorPreset.SEX],
        },
        reasonOfAppointment: {
            isValid: false,
            isRequired: true,
            validators: [],
        },
        provider: {
            isValid: true,
            isRequired: false,
            validators: [],
        },
        hasInsurance: {
            isValid: true,
            isRequired: false,
            validators: [],
        },
    },
    hasAdditionalInformation: false,
    showAdditionalDetailsForm: false,
    additionalDetails: {},
    patientHasAppointmentInLast7DaysModal: {
        isModalOpen: false,
        patientId: '',
    },
    procedures: [],
    locations: [],
    geocodingStarted: false,
    selectedLocationId: '',
    selectedAppointmentDateRangeFrom: '',
    selectedAppointmentDateRangeTo: '',
    selectedAppointmentDate: '',
    selectedAppointmentTime: '',
    submitting: false,
    availableTimes: [],
    selectedAppointmentAvailableTimes: [],
    loader: false,
    isCustomizationReady: false,
    autocompleteSessionUUID: uuidv4(),
    providers: [],
    insuranceProviders: [],
    insuranceSettings: {},
    apptManagementStep: AppointmentManagementStep.AUTHENTICATION,
    selectedApptUserAction: null,
    managedAppointmentDate: '',
    managedAppointmentRescheduleComplete: AppointmentManagementReschedulingStatus.RESCHEDULING,
    reschedulingConfiguration: {
        cancel_appointment: ReschedulingConfigurationType.DISABLE,
        reschedule_appointment: ReschedulingConfigurationType.DISABLE,
    },
    ...persistedData,
}

const updateAdditionalDetailsFields = (note = 'none', insurance = 'none') => {
    const fields: AdditionalDetails = {}

    if (note !== 'none') {
        fields.additionalNotes = {
            isValid: note === 'optional',
            isRequired: note === 'required',
            validators: [],
        }
    }

    if (insurance !== 'none') {
        fields.insuranceProviderSelector = {
            isValid: true,
            isRequired: false,
            validators: [],
        }
        fields.insuranceProvider = {
            isValid: insurance === 'optional',
            isRequired: insurance === 'required',
            validators: [],
        }
        fields.insurancePhone = {
            isValid: true,
            isRequired: false,
            validators: [ValidatorPreset.PHONE_NUMBER],
        }
        fields.insuranceGroup = {
            isValid: insurance === 'optional',
            isRequired: insurance === 'required',
            validators: [],
        }
        fields.isPolicyHolder = {
            isValid: insurance === 'optional',
            isRequired: insurance === 'required',
            validators: [],
        }
        fields.policyHolderIDSSN = {
            isValid: insurance === 'optional',
            isRequired: insurance === 'required',
            validators: [],
        }
        fields.policyHolderFirstName = {
            isValid: true,
            isRequired: false,
            validators: [ValidatorPreset.NAME],
        }
        fields.policyHolderLastName = {
            isValid: true,
            isRequired: false,
            validators: [ValidatorPreset.NAME],
        }
        fields.policyHolderDOB = {
            isValid: true,
            isRequired: false,
            validators: [ValidatorPreset.BIRTH_DATE],
        }
    }

    return fields
}

export function reducer(state: State, action: Actions): State {
    log.beforeStateChange({ type: action.type, state, action })

    const newState = reduceState(state, action)

    log.afterStateChange(newState)

    return newState
}

function reduceState(state: State, action: Actions): State {
    switch (action.type) {
        case 'RECEIVE_CUSTOMIZATION':
            const {
                insurance = 'none',
                additional_comments = 'none',
            } = action.customization.scheduling_additional_features_and_fields
            const hasAdditionalInformation =
                ['required', 'optional'].includes(insurance) || ['required', 'optional'].includes(additional_comments)

            return {
                ...state,
                customization: action.customization,
                hasAdditionalInformation,
                isCustomizationReady: true,
                additionalDetails: updateAdditionalDetailsFields(additional_comments, insurance),
                showAdditionalDetailsForm: hasAdditionalInformation,
                patientDetails: {
                    ...state.patientDetails,
                    ...(['optional', 'required'].includes(insurance)
                        ? {
                              hasInsurance: {
                                  isValid: false,
                                  isRequired: true,
                                  validators: [],
                                  value: undefined,
                              },
                          }
                        : {}),
                },
            }
        case 'CUSTOMIZATION_ERROR': {
            return {
                ...state,
                isCustomizationReady: true,
            }
        }
        case 'CHANGE_STEP':
            return {
                ...state,
                bookingStep: action.bookingStep,
                errorMessage: '',
            }
        case 'CHANGE_PATIENT_TYPE':
            return {
                ...state,
                patientType: action.patientType,
            }
        case 'UPDATE_PATIENT_DETAILS':
            if (!action.fieldName || !state.patientDetails[action.fieldName]) {
                return state
            }

            const isValid = !Boolean(action.error)

            let showAdditionalDetailsForm = state.showAdditionalDetailsForm

            if (action.fieldName === 'hasInsurance') {
                if (action.value === 'yes') {
                    showAdditionalDetailsForm = true
                } else {
                    showAdditionalDetailsForm = ['optional', 'required'].includes(
                        state.customization?.scheduling_additional_features_and_fields?.additional_comments ?? ''
                    )
                }
            }

            if (globalVars.isRSVP) {
                if (action.fieldName === 'reasonOfAppointment') {
                    state.treatmentLength = state.procedures.find((x) => x?.id === action.value)?.duration
                }
            }

            return mergeDeep(state, {
                showAdditionalDetailsForm,
                patientDetails: {
                    [action.fieldName]: {
                        value: action.value,
                        error: action.error,
                        isValid,
                        isDirty: true,
                    },
                },
            })
        case 'RESET_PATIENT_DETAILS':
            if (!action.fieldName || !state.patientDetails[action.fieldName]) {
                return state
            }

            return {
                ...state,
                patientDetails: {
                    ...state.patientDetails,
                    [action.fieldName]: initialState.patientDetails[action.fieldName],
                },
            }
        case 'UPDATE_ADDITIONAL_DETAILS':
            if (!action.fieldName || !state.additionalDetails[action.fieldName]) {
                return state
            }

            const nextState = mergeDeep(state, {
                additionalDetails: {
                    [action.fieldName]: {
                        value: action.value,
                        error: action.error,
                        isValid: !Boolean(action.error),
                        isDirty: action.isDirty ?? true,
                    },
                },
            })

            const optionalFields = ['policyHolderFirstName', 'policyHolderLastName', 'policyHolderDOB']
            const isInsuranceRequired =
                state.customization?.scheduling_additional_features_and_fields?.insurance === 'required'

            if (action.fieldName === 'isPolicyHolder') {
                optionalFields.forEach((fieldName) => {
                    nextState.additionalDetails[fieldName].isRequired =
                        action.value === 'no' ? isInsuranceRequired : false
                    nextState.additionalDetails[fieldName].isValid = !Boolean(
                        validator(nextState.additionalDetails[fieldName], nextState.additionalDetails[fieldName].value)
                    )
                })
            }

            return nextState
        case 'PROCEDURES_RECEIVED':
            return {
                ...state,
                procedures: action.procedures,
            }
        case 'RECEIVE_LOCATIONS':
            return {
                ...state,
                locations: action.locations,
            }
        case 'SET_SELECTED_LOCATION':
            return {
                ...state,
                selectedLocationId: action.locationId,
            }
        case 'HANDLE_SUBMITTING':
            return {
                ...state,
                submitting: true,
                errorMessage: '',
            }
        case 'PATIENT_SEARCH_NO_RESULTS':
            return {
                ...state,
                submitting: false,
                errorMessage: action.errorMessage,
            }
        case 'PATIENT_SEARCH_TOO_MANY_RESULTS':
            return {
                ...state,
                submitting: false,
                errorMessage: '',
            }
        case 'PATIENT_HAS_APPOINTMENTS_IN_LAST_7_DAYS': {
            return {
                ...state,
                submitting: false,
                errorMessage: '',
                patientHasAppointmentInLast7DaysModal: {
                    isModalOpen: true,
                    patientId: action.patientId,
                },
            }
        }
        case 'PATIENT_HAS_APPOINTMENTS_IN_LAST_7_DAYS_CANCEL': {
            return {
                ...state,
                patientHasAppointmentInLast7DaysModal: {
                    isModalOpen: false,
                    patientId: '',
                },
            }
        }
        case 'PATIENT_HAS_APPOINTMENTS_IN_LAST_7_DAYS_PROCEED': {
            return {
                ...state,
                patientHasAppointmentInLast7DaysModal: {
                    isModalOpen: false,
                    patientId: '',
                },
                patientId: action.patientId,
            }
        }
        case 'PATIENT_RECEIVED':
            return {
                ...state,
                submitting: false,
                errorMessage: '',
                patientId: action.patientId,
            }
        case 'SET_SELECTED_APPOINTMENT_DATE':
            return {
                ...state,
                selectedAppointmentDate: action.appointmentDate,
                selectedAppointmentAvailableTimes: [...state.availableTimes],
                selectedAppointmentTime: '',
            }
        case 'SET_SELECTED_APPOINTMENT_DATE_RANGE':
            return {
                ...state,
                selectedAppointmentDateRangeFrom: action.from,
                selectedAppointmentDateRangeTo: action.to,
            }
        case 'SET_SELECTED_APPOINTMENT_TIME':
            return {
                ...state,
                selectedAppointmentTime: action.appointmentTime,
            }
        case 'RECEIVE_AVAILABLE_TIMES':
            return {
                ...state,
                availableTimes: action.availability,
            }
        case 'RESET_APPOINTMENT_DATETIME':
            return {
                ...state,
                selectedAppointmentDate: '',
                selectedAppointmentTime: '',
                availableTimes: [],
            }
        case 'LOAD_RSVP_DATA':
            // TODOK: convert into a loop when Typescript gets smarter
            const patientDetails = action.data.patientDetails

            if (state.customization?.scheduling_additional_features_and_fields.insurance === 'none') {
                patientDetails.hasInsurance.isValid = true
                patientDetails.hasInsurance.isRequired = false
            }
            if (action.data.primaryProviderId) {
                patientDetails.provider.value = action.data.primaryProviderId
            }
            return {
                ...state,
                ...action.data,
                expiredRSVPData: undefined,
            }
        case 'LOAD_EXPIRED_RSVP_DATA':
            return {
                ...state,
                expiredRSVPData: action.data,
            }
        case 'SET_LOADER':
            return {
                ...state,
                loader: action.spin,
            }
        case 'HANDLE_ERROR':
            return {
                ...state,
                errorMessage: action.errorMessage,
                statusCode: action.statusCode || '',
            }
        case 'SET_DISTANCE_MATRIX':
            return {
                ...state,
                locations: action.locations,
            }
        case 'PROVIDERS_RECEIVED':
            return {
                ...state,
                providers: action.providers.sort((a, b) =>
                    `${a.last_name}${a.first_name}`.localeCompare(`${b.last_name}${b.first_name}`)
                ),
            }
        case 'INSURANCE_SETTINGS_RECEIVED':
            return {
                ...state,
                insuranceSettings: action.insuranceSettings,
            }
        case 'INSURANCE_PROVIDERS_RECEIVED':
            return {
                ...state,
                insuranceProviders: action.insuranceProviders,
            }
        case 'SET_STARTING_URL':
            return {
                ...state,
                startingURL: action.startingURL,
            }
        case 'CHANGE_APPT_MANAGEMENT_STEP':
            return {
                ...state,
                apptManagementStep: action.apptManagementStep,
            }
        case 'CHANGE_APPT_MANAGEMENT_USER_ACTION':
            return {
                ...state,
                selectedApptUserAction: action.selectedApptUserAction,
            }
        case 'UPDATE_AUTH_KEY':
            return {
                ...state,
                authKey: action.authKey,
            }
        case 'UPDATE_LOCATION_RESCHEDULING_CONFIGURATION': {
            return {
                ...state,
                reschedulingConfiguration: { ...state.reschedulingConfiguration, ...action.reschedulingConfiguration },
            }
        }
        case 'UPDATE_USER_MANAGED_APPT_DATE': {
            return {
                ...state,
                managedAppointmentDate: action.managedAppointmentDate,
            }
        }
        case 'UPDATE_USER_MANAGED_APPT_COMPLETION': {
            return {
                ...state,
                managedAppointmentRescheduleComplete: action.managedAppointmentRescheduleComplete,
            }
        }
        default:
            return state
    }
}

import { isValidPhoneNumber, formatPhoneNumber as formatPhoneNumberNational } from 'react-phone-number-input'
import { eraseState } from '../state-management/persist'

// tslint:disable
export function throttle(func: (args: any) => void, limit: number) {
    let lastFunc: NodeJS.Timeout
    let lastRan: number

    return function throttledFunction({ ...args }) {
        if (!lastRan) {
            func(args)
            lastRan = Date.now()
        } else {
            clearTimeout(lastFunc)
            lastFunc = setTimeout(function () {
                if (Date.now() - lastRan >= limit) {
                    func(args)
                    lastRan = Date.now()
                }
            }, limit - (Date.now() - lastRan))
        }
    }
}

export function debounce(func: (args: any) => void, delay: number) {
    let timeout: NodeJS.Timeout

    return function debouncedFunction({ ...args }) {
        clearTimeout(timeout)
        timeout = setTimeout(function () {
            func(args)
        }, delay)
    }
}

export const closeWidget = () => {
    try {
        eraseState()

        window.dataLayer?.push({ event: 'hideSelfScheduler' })
        window.dataLayer?.push({ event: 'simplifeyeSchedulingMinimize' })

        const message = {
            source: 'simplifeye',
            type: 'close-self-scheduling-widget',
            target: 'selfSchedulingWidget',
        }
        window.parent.postMessage(JSON.stringify(message), '*')
    } catch (err) {
        console.error(err)
    }
}

export const formatPhoneNumber = (phoneNumberString: string) => {
    const isValid = isValidPhoneNumber(phoneNumberString)
    return isValid ? formatPhoneNumberNational(phoneNumberString) : phoneNumberString
}

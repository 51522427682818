import locationHash from './locationHash'
import decode from 'jwt-decode'
import { UrlTokenPayload } from '../models/authentication'

let URL_TOKEN: string | null = null

if (locationHash.token && typeof locationHash.token === 'string') {
    URL_TOKEN = locationHash.token
}

export const decodeUrlToken = (): UrlTokenPayload | null => {
    if (!URL_TOKEN) return null
    try {
        return decode(URL_TOKEN)
    } catch (e) {
        return null
    }
}

export default URL_TOKEN

import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { ValueType } from '../models/patient'
import { Field } from '../models/patient'
import { Sex } from '../models/enums'
import { DATE_FORMAT } from './const'

dayjs.extend(isToday)

export enum ValidatorPreset {
    DEFAULT,
    NAME,
    EMAIL_ADDRESS,
    BIRTH_DATE,
    PHONE_NUMBER,
    SEX,
    APPOINTMENT_DATE,
}

const EMAIL_FORMAT = /^\w+([!#$%&'*\.+\-=?^_`{|]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
const PHONE_NUMBER_LENGTH = 11
const PHONE_NUMBER_ALLOWED_CHARS = /^[+]{0,1}[0-9\(\)\-\s]+$/

export const validator = (fieldData: Field, value?: ValueType): string | undefined => {
    if (fieldData.isRequired && !value) {
        return 'Required'
    }

    if (!fieldData.validators?.length) {
        return
    }

    if (!fieldData.isRequired && !value) {
        return
    }

    for (const preset of fieldData.validators) {
        switch (preset) {
            case ValidatorPreset.SEX:
                if (value && ![Sex.MALE, Sex.FEMALE].includes(value as Sex)) {
                    return 'Invalid Sex value'
                }
                return
            case ValidatorPreset.EMAIL_ADDRESS:
                if (!EMAIL_FORMAT.test(value as string)) {
                    return 'Invalid Email value'
                }
                return
            case ValidatorPreset.PHONE_NUMBER:
                const phoneNumber = value as string
                if (
                    !PHONE_NUMBER_ALLOWED_CHARS.test(phoneNumber) ||
                    phoneNumber.replace(/\D+/g, '').length !== PHONE_NUMBER_LENGTH
                ) {
                    return 'Invalid Phone # format'
                }
                if (!isValidPhoneNumber(phoneNumber)) {
                    return 'Invalid Phone # value'
                }
                return
            case ValidatorPreset.BIRTH_DATE: {
                const dateValue = value?.replace(/(_)$/, '')
                const parsed = dayjs(dateValue, DATE_FORMAT)
                const isValid = parsed.format(DATE_FORMAT) === dateValue
                if (
                    !isValid ||
                    parsed.isToday() ||
                    parsed.isAfter(dayjs()) ||
                    parsed.isBefore(dayjs('12/31/1899', DATE_FORMAT))
                ) {
                    return 'Invalid Date value'
                }
                return
            }
            case ValidatorPreset.APPOINTMENT_DATE: {
                const dateValue = value?.replace(/(_)$/, '')
                const parsed = dayjs(dateValue, DATE_FORMAT)
                const isValid = parsed.format(DATE_FORMAT) === dateValue
                const tomorrow = dayjs().add(1, 'day').format(DATE_FORMAT)

                if (!isValid) {
                    return 'Invalid Date value'
                }

                if (parsed.isBefore(tomorrow)) {
                    return 'Date should be in the future'
                }

                return
            }

            default:
        }
    }
}

import locationHash from '../shared/locationHash'

let WIDGET_KEY = 'none'

if (process.env.NODE_ENV !== 'production' && process.env.PREACT_APP_TESTING_WIDGET_KEY) {
    WIDGET_KEY = process.env.PREACT_APP_TESTING_WIDGET_KEY
}

if (process.env.NODE_ENV === 'production') {
    WIDGET_KEY = locationHash.key
}

export default WIDGET_KEY

const hash = window.location.hash.slice(1)

export default hash.includes('=')
    ? hash.split('&').reduce((map, part) => {
          const [key, value] = part.split('=')
          map[key] = value
          return map
      }, {} as { [key: string]: string })
    : {
          key: hash,
      }

import SSSocket from './socket'
import { Actions } from '../../state-management/reducer'
import { BookingStep, AppointmentManagementStep, AppointmentManagementReschedulingStatus } from '../../models/enums'
import URL_TOKEN from '../../shared/urlToken'

type Dispatcher = (action: Actions) => void

class SSDataEventsService {
    socket: any = null
    appointmentId: string | null = null
    connected: boolean = false
    dispatch: Dispatcher | null = null

    constructor(dispatch: Dispatcher) {
        this.dispatch = dispatch
        this.socket = new SSSocket()
    }

    connect() {
        this.socket.connect({
            onReconnectCallback: this.onReconnect.bind(this),
            onDisconnectCallback: this.onDisconnect.bind(this),
        })

        this.socket.register('appointment_confirmation', {
            dispatch: this.appointmentConfirmationEvent.bind(this),
            fallbackInterval: null,
            skipFirst: true,
        })
    }

    subscribe(id: string) {
        if (this.appointmentId) {
            this.unsubscribe()
        }
        this.appointmentId = id
        this.socket.subscribe(id)
    }

    unsubscribe() {
        this.appointmentId = null
        this.socket.unsubscribe()
    }

    onReconnect() {
        this.connected = true
    }

    onDisconnect() {
        this.appointmentId = null
        this.connected = false
    }

    disconnect() {
        this.socket.disconnect()
        this.onDisconnect()
    }

    async appointmentConfirmationEvent(data: { success: boolean }) {
        try {
            const isAppointmentManagement = URL_TOKEN && URL_TOKEN.length

            if (this.dispatch) {
                if (data?.success) {
                    if (isAppointmentManagement) {
                        this.dispatch({
                            type: 'CHANGE_APPT_MANAGEMENT_STEP',
                            apptManagementStep: AppointmentManagementStep.RESCHEDULED,
                        })
                        this.dispatch({
                            type: 'UPDATE_USER_MANAGED_APPT_COMPLETION',
                            managedAppointmentRescheduleComplete: AppointmentManagementReschedulingStatus.RESCHEDULED,
                        })
                    } else {
                        this.dispatch({
                            type: 'CHANGE_STEP',
                            bookingStep: BookingStep.BOOKED,
                        })
                    }
                } else {
                    if (isAppointmentManagement) {
                        this.dispatch({
                            type: 'CHANGE_APPT_MANAGEMENT_STEP',
                            apptManagementStep: AppointmentManagementStep.RESCHEDULED,
                        })
                        this.dispatch({
                            type: 'UPDATE_USER_MANAGED_APPT_COMPLETION',
                            managedAppointmentRescheduleComplete: AppointmentManagementReschedulingStatus.RESCHEDULING_FAILED,
                        })
                    } else {
                        this.dispatch({
                            type: 'CHANGE_STEP',
                            bookingStep: BookingStep.BOOKING_FAILED,
                        })
                    }
                }
            }
        } catch (e) {
            console.error(e)
        }
    }
}

export default SSDataEventsService

import * as scclient from 'socketcluster-client'
import encode from 'jwt-encode'

interface ConnectParams {
    onReconnectCallback: () => void
    onDisconnectCallback: () => void
}

type EventType = 'appointment_confirmation'

interface EventConfig {
    dispatch(): void
    fallbackInterval: number
    skipFirst: boolean
    fallbackIntervalId: number
    persistentIntervalId: number
}
export default class ChairfillSocket {
    socket: scclient.AGClientSocket | null = null
    eventConfigs: any = {}
    usePolling = false
    channel: any = null
    onReconnectCallback = () => {}
    onDisconnectCallback = () => {}
    numOfConnectEvents = 0

    connect({ onReconnectCallback, onDisconnectCallback }: ConnectParams) {
        this.onReconnectCallback = onReconnectCallback
        this.onDisconnectCallback = onDisconnectCallback

        this.socket = scclient.create({
            host: process.env.PREACT_APP_SOCKET_SERVER_HOST || '',
            secure: process.env.PREACT_APP_SECURE_WEBSOCKET_DOMAIN === 'true',
            autoConnect: false,
            autoReconnect: true,
            autoReconnectOptions: {
                initialDelay: 3000, //milliseconds
                randomness: 1000, //milliseconds
                multiplier: 1.5, //decimal
                maxDelay: 10000, //milliseconds
            },
        })

        this.onConnecting()
        this.onConnect()
        this.onDisconnect()
        this.onKickOut()
        this.onConnectAbort()
        this.onError()
        this.onAuthenticate()
        this.onSubscribe()
        this.onUnSubscribe()

        this.socket.connect()
    }

    // invokes dispatcher on first registration
    register(type: EventType, config: EventConfig) {
        const alreadyRegistered = this.eventConfigs[type] != null

        if (alreadyRegistered) {
            return
        }

        this.eventConfigs[type] = {
            dispatch: config.dispatch,
            fallbackInterval: config.fallbackInterval,
            persistentInterval: null,
        }

        if (!config.skipFirst) {
            config.dispatch()
        }
        this.fallbackPolling(type)
        this.persistentPolling(type)
    }

    disconnect() {
        this.channel?.kill()
        this.socket?.deauthenticate()
        this.socket?.disconnect()
        this.eventConfigs = {}
        this.socket = null
        this.usePolling = false
    }

    async auth() {
        try {
            if (this.socket && this.socket.authState === this.socket.UNAUTHENTICATED) {
                const token = encode(
                    { shared_secret: process.env.PREACT_APP_SOCKET_SERVER_SHARED_SECRET  },
                    process.env.PREACT_APP_SOCKET_SERVER_JWT_SECRET || ''
                )
                await this.socket.authenticate(token)
            }
        } catch (error) {
            console.error(error)
            this.usePolling = true
        }
    }

    private async onConnect() {
        if (!this.socket) {
            return
        }
        for await (let event of this.socket.listener('connect')) {
            console.log(`Socket ${event.id} is connected`)
            this.auth()
            this.usePolling = false
            this.numOfConnectEvents++
            if (this.numOfConnectEvents > 1) {
                this.onReconnectCallback()
            }
        }
    }

    private async onConnecting() {
        if (!this.socket) {
            return
        }
        for await (let _ of this.socket.listener('connecting')) {
            console.log(`Socket client is connecting`)
        }
    }

    private async onDisconnect() {
        if (!this.socket) {
            return
        }
        for await (let { code, reason } of this.socket.listener('disconnect')) {
            console.log(`Socket is disconnected with code ${code} and reason ${reason}.`)
            this.usePolling = true
            this.channel?.kill()
            this.onDisconnectCallback()
        }
    }

    private async onConnectAbort() {
        if (!this.socket) {
            return
        }
        for await (let { code, reason } of this.socket.listener('connectAbort')) {
            console.log(`Socket connection is aborted with code ${code} and reason ${reason}.`)
            this.usePolling = true
            this.channel?.kill()
            this.onDisconnectCallback()
        }
    }

    private async onAuthenticate() {
        if (!this.socket) {
            return
        }
        for await (let _ of this.socket.listener('authenticate')) {
            console.log('Chairfill socket is authenticated')
        }
    }

    private async onError() {
        if (!this.socket) {
            return
        }
        for await (let { error } of this.socket.listener('error')) {
            console.error(`Socket error ${error.message}.`)
            this.usePolling = true
        }
    }

    private async onSubscribe() {
        if (!this.socket) {
            return
        }
        for await (let { channel } of this.socket.listener('subscribe')) {
            console.log(`Socket client is subscribed to the channel ${channel}.`)
            this.usePolling = false
        }
    }

    private async onUnSubscribe() {
        if (!this.socket) {
            return
        }
        for await (let { channel } of this.socket.listener('unsubscribe')) {
            console.log(`Socket client is unsubscribed from the channel ${channel}.`)
        }
    }

    private async onKickOut() {
        if (!this.socket) {
            return
        }
        for await (let { channel, message } of this.socket.listener('kickOut')) {
            console.log(`Client is was kicked-out from the channel ${channel} with message ${message}.`)
            this.usePolling = false
        }
    }

    async subscribe(appointmentId: string) {
        if (!this.socket) {
            return
        }
        const channelName = `appointment-${appointmentId}`
        this.channel = this.socket.subscribe(channelName)
        await this.channel.listener('subscribe').once()
        this.setMessageConsumer()
    }

    async unsubscribe() {
        this.channel.unsubscribe()
        await this.channel.listener('unsubscribe').once()
        this.channel = null
    }

    private setMessageConsumer() {
        return (async () => {
            for await (let data of this.channel) {
                this.parseMessage(data)
            }
        })()
    }

    private parseMessage(payload: { type: string; data: unknown }) {
        if (process.env.NODE_ENV === 'development') {
            console.log('dispatching payload', payload)
        }

        const eventType = payload.type
        const data = payload.data

        const eventConfig = this.eventConfigs[eventType]
        if (eventConfig) {
            eventConfig.dispatch(data)
        } else {
            console.log('No dispatch found for event type ' + eventType)
        }
    }

    private fallbackPolling(type: EventType) {
        const eventConfig = this.eventConfigs[type]

        if (!eventConfig) {
            return
        }

        eventConfig.fallbackIntervalId = setTimeout(() => {
            if (eventConfig.fallbackInterval && this.usePolling) {
                eventConfig.dispatch()
                console.log('Fallback pooling', type, eventConfig.fallbackInterval)
            }
            this.fallbackPolling(type)
        }, eventConfig.fallbackInterval || 60 * 1000)
    }

    private persistentPolling(type: EventType) {
        const eventConfig = this.eventConfigs[type]

        if (!eventConfig) {
            return
        }

        eventConfig.persistentIntervalId = setTimeout(() => {
            if (eventConfig.persistentInterval) {
                eventConfig.dispatch()
            }
            this.persistentPolling(type)
        }, eventConfig.persistentInterval || 60 * 1000)
    }
}

import { h } from 'preact'
import * as style from './style.css'

interface Props {
    color?: string
    width?: number
}

const Spinner: preact.FunctionalComponent<Props> = ({ color = '#fff', width = 8 }) => {
    return (
        <div class={style.ldsRing}>
            {[0, 1, 2, 3].map((key) => (
                <div
                    key={key}
                    style={{
                        borderWidth: `${width}px`,
                        borderTopColor: color,
                    }}
                />
            ))}
        </div>
    )
}

export default Spinner

import WIDGET_KEY from '../../shared/widgetKey'

const config: {
    key: string
    storage: 'sessionStorage' | 'localStorage'
    blacklist: string[]
} = {
    key: `self-scheduling-state:${WIDGET_KEY}`,
    storage: 'sessionStorage',
    blacklist: ['submitting', 'loader', 'geocodingStarted'],
}

export default config

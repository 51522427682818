import config from './config'
import { State } from '../reducer'

export function removeBlacklistedProperties(state: State, blacklist: string[]): Partial<State> {
    if (!state) {
        return {}
    }

    const filteredState: { [key: string]: any } = {}

    for (const [key, value] of Object.entries(state)) {
        if (blacklist.indexOf(key) === -1) {
            filteredState[key] = value
        }
    }

    return filteredState
}

export function retrieve(): Partial<State> | null {
    try {
        const persistedData = window[config.storage].getItem(config.key)

        if (!persistedData) {
            return null
        }

        return JSON.parse(persistedData)
    } catch (err) {
        console.error(err)
        return null
    }
}

export function persist(state: State): void {
    try {
        const filteredState = removeBlacklistedProperties(state, config.blacklist)
        window[config.storage].setItem(config.key, JSON.stringify(filteredState))
    } catch (err) {
        console.error(err)
    }
}

export function eraseState(): void {
    try {
        window[config.storage].removeItem(config.key)
    } catch (err) {
        console.error(err)
    }
}
